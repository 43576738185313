import * as React from 'react';
import { createTheme, Theme, ThemeProvider } from '@mui/material/styles';
import { grey, pink } from '@mui/material/colors';
import Body from './Body';
import BellezaTTF from '../assets/fonts/Belleza-Regular.ttf';
import DancingScriptSemiBoldTTF from '../assets/fonts/DancingScript-SemiBold.ttf';
import AppRoutes from './AppRoutes';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

declare global {
  interface Window {
    theme: Theme;
  }
}

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: grey[500],
      light: grey[400],
      dark: grey[800],
    },
    secondary: {
      main: pink[700],
      light: pink[600],
      dark: pink[900],
    },
    background: {
      default: grey[900],
      paper: grey[800],
    },
  },
  direction: 'ltr',
  spacing: 20,
  typography: {
    fontFamily: 'Belleza, DancingScript',
    fontSize: 18,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'DancingScript';
          font-style: 'semi-bold';
          font-weight: 600;
          src: local('DancingScript'), local('DancingScript-SemiBold'), url(${DancingScriptSemiBoldTTF}) format('truetype');
        }

        @font-face {
          font-family: 'Belleza';
          font-style: 'normal';
          font-weight: 400;
          src: local('Belleza'), local('Belleza-Regular'), url(${BellezaTTF}) format('truetype');
        }
      `,
    },
    MuiPaper: {
      // styleOverrides: { root: { backgroundImage: 'unset' } },
    },
  },
});

interface AppProps {}

const App: React.FC<AppProps> = () => {
  window.theme = theme;
  return (
    <React.StrictMode>
      <StyledEngineProvider injectFirst={true}>
        <ThemeProvider theme={theme}>
          {/* cssbaseline needs to go after themeprovider so that the muicssbaseline overrides can take effect. */}
          <CssBaseline />
          <Body>
            <div
              style={{
                paddingTop: 50,
              }}
            >
              <AppRoutes />
            </div>
          </Body>
        </ThemeProvider>
      </StyledEngineProvider>
    </React.StrictMode>
  );
};

export default App;
