import * as React from 'react';
import { Typography, Theme, Box } from '@mui/material';
import { useTheme } from '@mui/styles';

interface PageHeaderProps {
  title: string;
  image: string;
  description?: string;
}

const PageHeader: React.FC<PageHeaderProps> = (props) => {
  const { title, description } = props;
  const theme = useTheme();

  return (
    <>
      {/* Hero unit */}
      <Box
        sx={{
          backgroundImage: `url(${props.image})`,
          backgroundSize: 'cover',
          backgroundPosition: '50% 30%',
        }}
      >
        <Box
          sx={{
            maxWidth: 600,
            margin: '0 auto',
            padding: `${theme.spacing(8)} 0 ${theme.spacing(6)}`,
            color: theme.palette.common.white,
          }}
        >
          <Typography
            component="h1"
            variant="h2"
            align="center"
            gutterBottom
            fontFamily="DancingScript"
          >
            {title}
          </Typography>
          <Typography variant="h6" align="center" paragraph>
            {description}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default PageHeader;
